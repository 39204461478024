<div class="row animated fadeIn m-0 justify-content-center align-items-center vh-100 ">
    <div class="col-10">
        <div class="card">
            <div class="card-body">

              <div>

                <select class="form-control text-uppercase has-dropdown" name="tipo" #input (change)="buscarOferta( input.value )">
                  <option value="">TODAS LAS CATEGORIAS</option>
                  <option value="NIÑERAS">NIÑERAS</option>
                  <option value="CUIDADO ADULTO MAYOR">CUIDADO ADULTO MAYOR</option>
                  <option value="SERVICIO DOMESTICO">SERVICIO DOMESTICO</option>
                  <option value="CUIDADO DE MASCOTAS">CUIDADO DE MASCOTAS</option>
                  <option value="CUIDADOS CAPACIDADES ESPECIALES">CUIDADOS CAPACIDADES ESPECIALES</option>
                  <option value="TUTORIAS ESCOLARES">TUTORIAS ESCOLARES</option>
                  <option value="TRABAJOS DEL HOGAR">TRABAJOS DEL HOGAR</option>
                  <option value="ASISTENCIA AUTOMOTRIZ">ASISTENCIA AUTOMOTRIZ</option>
                  <option value="MENSAJERIA">MENSAJERIA</option>
                  <option value="SPA">SPA</option>
                  <option value="OTROS">OTROS</option>
                  <option value="PROFESIONALES TITULADOS">PROFESIONALES TITULADOS</option>
                </select>

              </div>
              <!--   <input #input (keyup)="buscarOferta( input.value )" type="text" class="form-control" placeholder="Buscar ofertas..."> -->

            </div>
        </div>
    </div>
</div>



<div class="row animated fadeIn" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fa fa-refresh fa-spin fa-2x"></i>
            <br>
            <span>Espere por favor</span>
        </div>
    </div>
</div>


<div class=" container p-3 " class="col-sm">
    <h3 align="center" id="color">OFERTAS PUBLICADA</h3>
</div>

<div class="container">
    <div class="row" *ngIf="!cargando">
        <div class="col-12">
            <div class="card mb-3" style="max-width: 100%;" *ngFor="let form of  formularios| paginate: { itemsPerPage: 5, currentPage: totalRegistros }; let i = index">
                <div class="row g-0">
                <div class="col-md-4">
                    <p class="numero">{{i+1}}</p>
                    <br>
                    <h2 style="text-align: center;" class="card-title">{{form.tituloEmpleo}}</h2>
                    <p style="text-align: center;" class="card-text"><small class="text-muted"><strong>{{form.categorias}} / {{form.fechaReporte  | date:'dd-MM-yyyy'}} / {{form.estado}}</strong></small></p>
                </div>
                <div class="col-md-8">
                    <div class="card-body">
                    <h3>Descripción de la oferta</h3>
                    <p style="text-align: justify;line-height: 17px;" class="card-text">{{form.descripcionEmpleo}}</p>
                    <p class="card-text">Remuneración {{form.remuneracion}}</p>
                    <p class="card-text">Por el valor de ${{form.valor}}</p>
                    <p class="card-text">{{form.provincia}} - {{form.ciudad}}</p>
                    <p class="card-text">{{form.emailEmpleador}}</p>
                    <p class="card-text">{{form.telefonoEmpleador}}</p>
                    <div class="row g-0">
                        <div class="col-md-12">
                            <button type="button" class="btn btn-outline-primary boton"
                                    (click)="calificando(form._id,form.usuario, form.emailEmpleador)"
                                                                ><b>POSTULAR</b></button>
                        </div>

                        <div style="margin-top:1%" class="col-md-12">
                            <button type="button" class="btn btn-outline-primary boton"
                                                [routerLink]="['/dashboard/ofertaCompleta/',form._id]"
                                    ><b>VER OFERTA</b></button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <br>
            <br>
            <pagination-controls (pageChange)="totalRegistros = $event"></pagination-controls>
        </div>
    </div>
</div>


  <!--<div class="container">
    <div class="row" *ngIf="!cargando">
        <div class="col-12">
            <table class="table table-hover table-responsive tabla" id="tableColor">
                <thead>
                    <tr>
                        <th scope="col" COLSPAN=1 style="background: azure">Nº</th>
                        <th scope="col" COLSPAN=1 style="background: azure">TÍTULO EMPLEO</th>
                        <th scope="col" COLSPAN=1 style="background: azure">VALOR</th>

                        <th scope="col" COLSPAN=1 style="background: azure">CATEGORIA</th>
                        <th scope="col" COLSPAN=1 style="background: azure">CIUDAD</th>
                        <th scope="col" COLSPAN=1 style="background: azure">FECHA PUBLICACIÓN</th>
                        <th scope="col" COLSPAN=1 style="background: azure">VER OFERTA</th>
                        <th scope="col" COLSPAN=1 style="background: azure">ACCIÓN</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let form of  formularios| paginate: { itemsPerPage: 10, currentPage: totalRegistros }; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{form.tituloEmpleo}}</td>
                        <td>${{form.valor}}</td>

                        <td>
                          <div  *ngIf="(form.tipoPlan ==='Premium (3 meses)'|| form.tipoPlan ==='Premium (6 meses)')" >
                          <ng-lottie [options]="options" width="20px" height="40px"></ng-lottie>
                         </div>

                          {{form.categorias}}
                        </td>
                        <td>{{form.ciudad}}</td>
                        <td>
                            {{form.fechaReporte  | date:'dd-MM-yyyy'}}
                        </td>
                        <td>
                            <button type="button" class="btn btn-outline-primary"
                                       style="width: 75px;height: 70;font-size: 50%;"
                                       [routerLink]="['/dashboard/ofertaCompleta/',form._id]"
                           ><b>VER OFERTA</b></button>

                        </td>

                        <td >
                          <button type="button" class="btn btn-outline-primary"
                          style="width: 140px;height: 75;font-size: 70%;" (click)="calificando(form._id,form.usuario, form.emailEmpleador)"
                                                    ><b>Postular</b></button>

                        </td>




                    </tr>
                </tbody>
            </table>
            <br>
            <br>
            <pagination-controls (pageChange)="totalRegistros = $event"></pagination-controls>
        </div>
    </div>
</div>-->

