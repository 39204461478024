<div class=" container p-3 " class="col-sm">
    <h3 align="center" id="color">CURSOS PARA PUBLICAR</h3>
</div>


<div class="container">
    <div class="row">
        <div class="col-12">
            <table class="table table-hover table-responsive btn-table  table-bordered "  id="tableColor" >
                <thead>
                    <tr>
                        <th scope="col" COLSPAN=1 style="background: azure">Nº</th>
                        <th scope="col" COLSPAN=1 style="background: azure">TÍTULO CURSO</th>
                        <th scope="col" COLSPAN=1 style="background: azure">CATEGORIA</th>
                        <th scope="col" COLSPAN=1 style="background: azure">FECHA CREACIÓN</th>


                        <th scope="col" COLSPAN=1 style="background: azure">VALOR</th>


                        <th scope="col" COLSPAN=1 style="background: azure">ESTADO</th>
                        <th scope="col" COLSPAN=1 style="background: azure">TEMARIO</th>
                        <th scope="col" COLSPAN=1 style="background: azure">ACCIÓN</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let form of  formularios| paginate: { itemsPerPage: 5, currentPage: totalRegistros }; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{form.tituloCurso}}</td>
                        <td>{{form.categorias}}</td>
                        <td>{{form.fechaReporte |date:'dd-MM-yyyy'}}</td>

                        <td>{{form.valor |currency:'USD'}}</td>



                        <td class="text" [ngClass]="{'bg-primary text-white':form.estado=='PUBLICADO','bg-danger text-white':form.estado=='NO PUBLICADO'}">{{form.estado}}</td>

                        <td align="center">


                          <a type="button" class="btn btn-outline-primary"*ngIf="form.urlPdf != null" href="{{form.urlPdf}}"
                          style="width: 100px;height: 70;font-size: 50%;"   target="_blank"
                                                            ><b>VER TEMARIO</b></a>
                                                            <span *ngIf="form.urlPdf == null">NO HAY TEMARIO</span>
                         </td>
                        <td>
                            <button type="button" class="btn btn-outline-primary"
                                       style="width: 75px;height: 70;font-size: 50%;"
                            [routerLink]="['/dashboard/editarCursos/',form._id]"><b>EDITAR</b></button>

                        </td>
                    </tr>
                </tbody>
            </table>
            <pagination-controls (pageChange)="totalRegistros = $event"></pagination-controls>
        </div>
    </div>
</div>
