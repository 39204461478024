<div class="container-fluid form">
    <div class="row form-row ">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div class="card my-5">
                <div class="card-body">
                    <h5 class="card-title text-center">Olvidó su contraseña</h5>
                    <div>
                        <div id="errorMsg" *ngIf="errorMessage">
                            <span>{{errorMessage}}</span>
                        </div>
                        <div id="successMsg" *ngIf="successMessage">
                            <span>{{successMessage}}</span>
                        </div>
                        <form action="" [formGroup]="RequestResetForm" (ngSubmit)="RequestResetUser(RequestResetForm)">
                            <div class="form-group">
                                <input _ngcontent-c0="" class="form-control form-control-lg" placeholder="CORREO ELECTRONICO"
                                    type="text" id="email" formControlName="email" />
                                <span *ngIf="!RequestResetForm.get('email').valid && !IsvalidForm"
                                    class="help-block">¡Por favor introduzca una dirección de correo electrónico válida!</span>
                            </div>
                            <div class="form-group">
                                <div>
                                    <button type="submit" class=" btn btn-primary">Restablecer la contraseña</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>