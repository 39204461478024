export class Cursos {

    _id: string;
    tituloCurso: string;
    descripcionCurso: string;
    valor:number;
    horario:string;
    categorias: string;
    urlPdf:string

   estado: String;

   }
