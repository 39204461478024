<app-header-pagina></app-header-pagina>



<section >
    <br>
    <br>
  
    
    <h2 align="center" joyrideStep="one" title="Bienvenido" text="En esta sección seleccionas la categoria de tu preferencia para que puedas registrarte y publicar un empleo ">¡Hola! ¿Selecciona una de nuestras categorias para publicar?</h2>
<br>

<div class="row m-0 justify-content-center align-items-center vh-100" >
    
    <!-- Column -->
   
    <a class="nav-link waves-effect waves-dark " [routerLink]="['/nineraEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/nineraEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/ninera3.png" width="40" height="40"></i><br>Niñera</button>
          <div class="notify"> <span class=""></span></div>
      </a>
      <a class="nav-link  " [routerLink]="['/cuidadoAdultoEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class=" btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/cuidadoAdultoEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/edad3.png" width="40" height="40"></i><br>Cuidado del adulto mayor</button>
          <div class="notify"> <span class=""></span></div>
      </a>
    <!-- Column -->
    <a class="nav-link  " [routerLink]="['/domesticoEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/domesticoEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/Icono Sericio Domestico.png" width="40" height="40"></i><br>Servicio Doméstico</button>
          <div class="notify"> <span class=""></span></div>
      </a>
      <a class="nav-link  " [routerLink]="['/mascotaEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/mascotaEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/Icono Cuidado de Mascotas.png" width="40" height="40"></i><br>Cuidado de mascotas</button>
          <div class="notify"> <span class=""></span></div>
      </a>
      <a class="nav-link  " [routerLink]="['/spaEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/spaEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/Icono Spa.png" width="40" height="40"></i><br>Spa</button>
          <div class="notify"> <span class=""></span></div>
      </a>

      <a class="nav-link  " [routerLink]="['/capacidadesEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/capacidadesEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/Capacidades especiales.svg" width="40" height="40"></i><br>Cuidados capacidades Especiales</button>
          <div class="notify"> <span class=""></span></div>
      </a>
 
      <a class="nav-link  " [routerLink]="['/tutoriasEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/tutoriasEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/Icono Tutorias Escolares.png" width="40" height="40"></i><br>Tutorías escolares</button>
          <div class="notify"> <span class=""></span></div>
      </a>
 
      <a class="nav-link  " [routerLink]="['/trabajosEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/trabajosEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/Icono Trabajos del Hogar.png" width="40" height="40"></i><br>Trabajos del hogar</button>
          <div class="notify"> <span class=""></span></div>
      </a>

      <a class="nav-link  " [routerLink]="['/asistenciaEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/asistenciaEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/Icono Servicio Automotriz.png" width="40" height="40"></i><br>Asistencia automotriz</button>
          <div class="notify"> <span class=""></span></div>
      </a>

      <a class="nav-link  " [routerLink]="['/mensajeriaEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/mensajeriaEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/Icono Mensajeria.png" width="40" height="40"></i><br>Mensajería</button>
          <div class="notify"> <span class=""></span></div>
      </a>
      <a class="nav-link  " [routerLink]="['/profesionalEmpleador']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/profesionalEmpleador']" routerLinkActive="router-link-active" ><img src="../../../assets/images/Iconografia/Icono Profesionales.png" width="40" height="40"></i><br>Profesionales Titulados</button>
          <div class="notify"> <span class=""></span></div>
      </a>
      <a class="nav-link  " [routerLink]="['/inicio']" routerLinkActive="router-link-active"> 
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i  [routerLink]="['/inicio']" routerLinkActive="router-link-active" ></i><br>Regresar a inicio</button>
          <div class="notify"> <span class=""></span></div>
      </a>



  
  
 
    
      
   
</div>
     <br>
     <br>
     <br>
    
      
    </section>


    <section>

        <h3 align="center">¿Ya eres miembro? <a [routerLink]="['/login']" routerLinkActive="router-link-active" >Inicia sesión</a>&nbsp;&nbsp;
          <button type="button" class="btn btn-success" (click)="home2()">?<i class="bi-patch-question"></i></button>
        </h3>

    </section>

 <app-footer></app-footer>
