<div class=" container p-3 " class="col-sm">
    <h3 align="center" id="color">CURSOS DISPONIBLES</h3>
  </div>
  <br>
  <br>

  <div class="container">
      <div class="row">
        <div class="card caja0">      
            <tr *ngFor="let form of  formularios| paginate: { itemsPerPage: 1, currentPage: totalRegistros }; let i = index" style="padding:2%">
                <!--<h5 class= "card-header" style="background: azure">Nº</h5>
                <p class="card-text">{{i+1}}</p>--> 
                <h5 class= "card-header" style="background: azure">TÍTULO CURSO</h5>
                <p class="card-text">{{form.tituloCurso}}</p>
                <h5 class= "card-header" style="background: azure" >CATEGORIA</h5>
                <p class="card-text">{{form.categorias}}</p>
                <h5 class= "card-header" style="background: azure"  >DESCRIPCIÓN</h5>
                <p class="card-text">{{form.descripcionCurso}}</p>
                <h5 class= "card-header" style="background: azure">FECHA CREACIÓN</h5>
                <p class="card-text">{{form.fechaReporte |date:'dd-MM-yyyy'}}</p>
                <h5 class= "card-header" style="background: azure">VALOR</h5>
                <p class="card-text">{{form.valor|currency:'USD'}}</p>
                <h5 class= "card-header" style="background: azure">TEMARIO</h5>
                <p class="card-text">{{form.fechaReporte | date:'dd-MM-yyyy'}}</p>
                <h5 class= "card-header" style="background: azure">FECHA DE CREACIÓN</h5>
                <p class="card-text">{{form.fechaReporte | date:'dd-MM-yyyy'}}</p>
                <div class="row">
                    <div style="padding-left: 4%;padding-bottom: 2%;" class="col-6">
                        <a type="button" class="btn btn-outline-primary"*ngIf="form.urlPdf != null" href="{{form.urlPdf}}" style="width: 100%;height: auto;font-size: 100%;"   target="_blank"><b>VER TEMARIO</b></a>
                        <span *ngIf="form.urlPdf == null">NO HAY TEMARIO</span>
                    </div>
                    <div style="padding-right: 4%;padding-bottom: 2%;" class="col-6">
                        <button type="button" class="btn btn-outline-primary" style="width: 100%;height: auto;font-size: 100%;" (click)="multi(form.valor);producto();datosCurso(form.tituloCurso,form.categorias,form.descripcionCurso,form.valor) "><b>COMPRAR</b></button>
                    </div>
                </div>
            </tr>
        </div>
        <br>
        <br>
        <pagination-controls (pageChange)="totalRegistros = $event"></pagination-controls>
      </div>
  </div>
