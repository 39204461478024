
<div class="row animated fadeIn ">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <input #input (keyup)="buscarUsuario( input.value )" type="text" class="form-control" placeholder="Buscar usuario...">

            </div>
        </div>
    </div>
</div>

<div class="row animated fadeIn" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fa fa-refresh fa-spin fa-2x"></i>
            <br>
            <span>Espere por favor</span>
        </div>
    </div>
</div>


<div class="row animated fadeIn" *ngIf="!cargando">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <h3 align="center" class="card-title">Usuarios registrados ( <small>{{totalRegistros }}</small> )</h3>


                <table class="table table-hover table-responsive btn-table  table-bordered tabla">
                    <thead>
                        <tr>
                            <th scope="col" COLSPAN=1 style="background: azure">Imagen</th>
                            <th scope="col" COLSPAN=1 style="background: azure">Correo</th>
                            <th scope="col" COLSPAN=1 style="background: azure">Usuario</th>
                            <th scope="col" COLSPAN=1 style="background: azure">Role</th>
                            <th scope="col" COLSPAN=1 style="background: azure"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let usuario of usuarios">
                            <td class="w70">
                                <img  [src]="usuario.img | imagen" class="img-50 img-circle pointer">
                            </td>
                            <td>
                                {{ usuario.email }}
                            </td>
                            <td>{{ usuario.usuario }}</td>
                            <td>

                                <select  (change)="guardarUsuario( usuario )" [(ngModel)]="usuario.role" name="role" class="form-control" [ngClass]="{'bg-danger text-white':usuario.role=='INACTIVO'}">
                                    <option value="ADMIN_ROLE">ADMIN_ROLE</option>
                                    <option value="USER_ROLE">USER_ROLE</option>
                                    <option value="EMPLEADOR_ROLE">EMPLEADOR_ROLE</option>
                                    <option value="EMPLEADO_ROLE">EMPLEADO_ROLE</option>
                                    <option value="CAPACITATE_ROLE">CAPACITATE_ROLE</option>
                                    <option value="INACTIVO">INACTIVO</option>
                                </select>

                            </td>

                            <td>
                                <button (click)="guardarUsuario( usuario )" class="btn btn-primary">
                                <i class="fa fa-save"></i>
                              </button>
                              &nbsp;
                                <button (click)="borrarUsuario( usuario )" class="btn btn-danger">
                                  <i class="fa fa-trash-o"></i>
                                </button>
                            </td>
                        </tr>

                    </tbody>

                </table>
<br>
<br>
                <button (click)="cambiarDesde(-20)" class="btn btn-secondary">
                  Anteriores
                </button>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button (click)="cambiarDesde(20)" class="btn btn-secondary">
                    Siguientes
                </button>



            </div>
        </div>
    </div>
</div>
