<div class="col-12 ">
    <div class="card">
        <div class="card-body">


            <div class="row animated fadeIn ">
                <div class="col-10">
                    <div class="card">
                        <div class="card-body">

                            <input #input (keyup)="buscarHoja( input.value )" type="text" class="form-control"
                                placeholder="Buscar Perfiles...">

                        </div>
                    </div>
                </div>
            </div>

            <div class=" container p-3 " class="col-sm">
                <h3 align="center" id="color">PERFILES PUBLICADOS</h3>
            </div>

            <div class="row animated fadeIn" *ngIf="cargando">

                <div class="col-sm-12">

                    <div class="alert alert-warning text-center">
                        <strong>Cargando</strong>
                        <br>
                        <i class="fa fa-refresh fa-spin fa-2x"></i>
                        <br>
                        <span>Espere por favor</span>
                    </div>
                </div>
            </div>



            <table class="table table-hover table-responsive btn-table  table-bordered tabla"  *ngIf="!cargando">
                <thead>
                    <tr>
                        <th scope="col" COLSPAN=1 style="background: azure">IMAGEN</th>
                        <th scope="col" COLSPAN=1 style="background: azure">NOMBRE</th>
                        <th scope="col" COLSPAN=1 style="background: azure">DESCRIPCIÓN</th>
                        <th scope="col" COLSPAN=1 style="background: azure">TELÉFONO</th>


                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let form of  formularios | paginate: { itemsPerPage: 5, currentPage: totalRegistros }">
                        <td class="w70">
                            <img [src]="form.usuario.img |imagen" class="img-50 img-circle pointer">
                        </td>
                        <td> {{form.nombre}} {{form.apellido}}</td>
                        <td>{{form.descripcion}}</td>
                        <td> {{form.usuario.telefono}} </td>


                    </tr>

                </tbody>

            </table>





        </div>
    </div>
</div>
<pagination-controls (pageChange)="totalRegistros = $event" *ngIf="!cargando"></pagination-controls>
