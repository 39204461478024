<div class=" container p-3 " class="col-sm">
    <h3 align="center" id="color">CURSOS COMPRADOS</h3>
  </div>
  <br>
  <br>
  
  <div class="container">
      <div class="row">
        <div class="card caja0">      
            <tr *ngFor="let form of  formularios| paginate: { itemsPerPage: 3, currentPage: totalRegistros }; let i = index" style="padding:2%">
                <!--<h5 class= "card-header" style="background: azure">Nº</h5>
                <p class="card-text">{{i+1}}</p>--> 
                <h5 class= "card-header" style="background: azure">TÍTULO CURSO</h5>
                <p class="card-text">{{form.tituloCurso}}</p>
                <h5 class= "card-header" style="background: azure" >CATEGORIA</h5>
                <p class="card-text">{{form.categorias}}</p>
                <h5 class= "card-header" style="background: azure"  >DESCRIPCIÓN</h5>
                <p class="card-text">{{form.descripcionCurso}}</p>
                <h5 class= "card-header" style="background: azure">FECHA CREACIÓN</h5>
                <p class="card-text">{{form.fechaReporte |date:'dd-MM-yyyy'}}</p>
                <h5 class= "card-header" style="background: azure">VALOR</h5>
                <p class="card-text">{{form.valor|currency:'USD'}}</p>
            </tr>
        </div>
        <br>
        <br>
        <pagination-controls (pageChange)="totalRegistros = $event"></pagination-controls>
      </div>
  </div>
