<div class=" container p-3 " class="col-sm">
  <h3 align="center" id="color">CURSOS COMPRADOS</h3>
</div>
<br>
<br>

<div class="container">
    <div class="row">
        <div class="col-12">
            <table class="table table-hover table-responsive btn-table  "   id="tableColor" >
                <thead>
                    <tr>
                        <th scope="col" COLSPAN=1 style="background: azure">Nº</th>
                        <th scope="col" COLSPAN=1 style="background: azure">TÍTULO CURSO</th>
                        <th scope="col" COLSPAN=1 style="background: azure">CATEGORIA</th>
                        <th scope="col" COLSPAN=1 style="background: azure">DESCRIPCIÓN</th>
                        <th scope="col" COLSPAN=1 style="background: azure">FECHA CREACIÓN</th>


                        <th scope="col" COLSPAN=1 style="background: azure">VALOR</th>


                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let form of  formularios| paginate: { itemsPerPage: 5, currentPage: totalRegistros }; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{form.tituloCurso}}</td>
                        <td>{{form.categorias}}</td>
                        <td>{{form.descripcionCurso}}</td>
                        <td>{{form.fechaReporte |date:'dd-MM-yyyy'}}</td>

                        <td>{{form.valor|currency:'USD'}}</td>





                    </tr>
                </tbody>
            </table>
            <pagination-controls (pageChange)="totalRegistros = $event"></pagination-controls>
        </div>
    </div>
</div>
