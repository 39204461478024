<div class=" container p-3 " class="col-sm">
    <h3 align="center" id="color">OFERTAS PUBLICADAS</h3>
</div>





<div class="container">

    
<div class="row animated fadeIn m-0 justify-content-center align-items-center vh-100 ">
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <input #input (keyup)="buscarOferta( input.value )" type="text" class="form-control" placeholder="Buscar ofertas...">

            </div>
        </div>
    </div>
</div>



<div class="row animated fadeIn" *ngIf="cargando">

    <div class="col-sm-12">

        <div class="alert alert-warning text-center">
            <strong>Cargando</strong>
            <br>
            <i class="fa fa-refresh fa-spin fa-2x"></i>
            <br>
            <span>Espere por favor</span>
        </div>
    </div>
</div>

    <div class="row">
        <div class="col-12">
            <table class="table table-hover table-responsive btn-table  table-bordered tabla"    id="tableColor" >
                <thead>
                    <tr>
                        <th scope="col" COLSPAN=1 style="background: azure">Nº</th>
                        <th scope="col" COLSPAN=1 style="background: azure">TÍTULO EMPLEO</th>
                        <th scope="col" COLSPAN=1 style="background: azure">VALOR</th>
                   
                        <th scope="col" COLSPAN=1 style="background: azure">HORARIO</th>
                        <th scope="col" COLSPAN=1 style="background: azure">CATEGORIA</th>
                        <th scope="col" COLSPAN=1 style="background: azure">CIUDAD</th>
                     
                        <th scope="col" COLSPAN=1 style="background: azure">ACCIÓN</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let form of  formularios| paginate: { itemsPerPage: 10, currentPage: totalRegistros }; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{form.tituloEmpleo}}</td>
                        <td>${{form.valor}}</td>
                        <td>{{form.horario}}</td>
                        <td>{{form.categorias}}</td>
                        
                        <td>{{form.ciudad}}</td>
                
                  <td>
                            <button type="button" class="btn btn-outline-primary"
                                       style="width: 75px;height: 70;font-size: 50%;"
                                       [routerLink]="['/dashboard/editaroferta/',form._id]" 
                           ><b>VER OFERTA</b></button>
                        
                        </td> 
                    </tr>                  
                </tbody>
            </table>
            <pagination-controls (pageChange)="totalRegistros = $event"></pagination-controls>
        </div>
    </div>
</div>
