<div id="main-wrapper">

    <app-header></app-header>


    <app-sidebar></app-sidebar>

    <div class="page-wrapper">

      <div class="container-fluid">

     <app-breadcrumbs></app-breadcrumbs>
  <!-- mi istema de rutas -->


  <!-- Start Page Content -->
                  <!-- ============================================================== -->
                  <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">

                              <router-outlet>


                              </router-outlet>

                            </div>
                        </div>
                    </div>
                </div>
                <!-- ============================================================== -->
                <!-- End PAge Content -->

      </div>
          <!-- footer -->
              <!-- ============================================================== -->
              <footer class="footer">
                © {{year}} Jobandcare
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
    </div>

  </div>

