<app-header-pagina></app-header-pagina>

<section>
  <br>


  <h2 align="center" joyrideStep="uno" title="Bienvenido"
    text="En esta sección seleccionas la categoria de tu preferencia para que puedas registrarte y encontrar empleo ">
    ¡Hola! ¿Selecciona una de nuestras categorias?</h2>
  <br>

  <div>
    <div class="row m-0 justify-content-center align-items-center vh-100">
      <!-- Column -->

      <a class="nav-link  " [routerLink]="['/ninera']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i
            [routerLink]="['/ninera']" routerLinkActive="router-link-active" class="" aria-hidden="true"><img
              src="../../../assets/images/Iconografia/ninera3.png" width="40" height="40"></i><br> Niñera</button>
        <div class="notify"> <span class=""></span></div>
      </a>
      <a class="nav-link  " [routerLink]="['/cuidadoAdulto']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i
            [routerLink]="['/cuidadoAdulto']" routerLinkActive="router-link-active" class="" aria-hidden="true"><img
              src="../../../assets/images/Iconografia/edad3.png" width="40" height="40"></i><br>Cuidado del adulto
          mayor</button>
        <div class="notify"> <span class=""></span></div>
      </a>
      <!-- Column -->
      <a class="nav-link  " [routerLink]="['/domestico']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado "><i
            [routerLink]="['/domestico']" routerLinkActive="router-link-active"><img
              src="../../../assets/images/Iconografia/Icono Sericio Domestico.png" width="40"
              height="40"></i><br>Servicio Doméstico</button>
        <div class="notify"> <span class=""></span></div>
      </a>



      <a class="nav-link  " [routerLink]="['/mascota']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i
            [routerLink]="['/mascota']" routerLinkActive="router-link-active"><img
              src="../../../assets/images/Iconografia/Icono Cuidado de Mascotas.png" width="40"
              height="40"></i><br>Cuidado de mascotas</button>
        <div class="notify"> <span class=""></span></div>
      </a>
    </div>
    <div class="row m-0 justify-content-center align-items-center vh-100">
      <a class="nav-link  " [routerLink]="['/spa']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i [routerLink]="['/spa']"
            routerLinkActive="router-link-active"><img src="../../../assets/images/Iconografia/Icono Spa.png" width="40"
              height="40"></i><br>Spa</button>
        <div class="notify"> <span class=""></span></div>
      </a>

      <a class="nav-link  " [routerLink]="['/capacidades']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i
            [routerLink]="['/capacidades']" routerLinkActive="router-link-active" class="" aria-hidden="true"><img
              src="../../../assets/images/Iconografia/Capacidades especiales.svg" width="35"
              height="35"></i><br>Cuidados
          capacidades Especiales</button>
        <div class="notify"> <span class=""></span></div>
      </a>

      <a class="nav-link  " [routerLink]="['/tutorias']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i
            [routerLink]="['/tutorias']" routerLinkActive="router-link-active"><img
              src="../../../assets/images/Iconografia/Icono Tutorias Escolares.png" width="40"
              height="40"></i><br>Tutorías escolares</button>
        <div class="notify"> <span class=""></span></div>
      </a>

      <a class="nav-link  " [routerLink]="['/trabajos']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i
            [routerLink]="['/trabajos']" routerLinkActive="router-link-active"><img
              src="../../../assets/images/Iconografia/Icono Trabajos del Hogar.png" width="40"
              height="40"></i><br>Trabajos del hogar</button>
        <div class="notify"> <span class=""></span></div>
      </a>
    </div>
    <div class="row m-0 justify-content-center align-items-center vh-100">
      <a class="nav-link  " [routerLink]="['/asistencia']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i
            [routerLink]="['/asistencia']" routerLinkActive="router-link-active" class="" aria-hidden="true"><img
              src="../../../assets/images/Iconografia/Icono Servicio Automotriz.png" width="40"
              height="40"></i><br>Asistencia automotriz</button>
        <div class="notify"> <span class=""></span></div>
      </a>

      <a class="nav-link  " [routerLink]="['/mensajeria']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i
            [routerLink]="['/mensajeria']" routerLinkActive="router-link-active" class="" aria-hidden="true"><img
              src="../../../assets/images/Iconografia/Icono Mensajeria.png" width="40"
              height="40"></i><br>Mensajería</button>
        <div class="notify"> <span class=""></span></div>
      </a>
      <a class="nav-link  " [routerLink]="['/profesional']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"><i
            [routerLink]="['/profesional']" routerLinkActive="router-link-active" class="" aria-hidden="true"><img
              src="../../../assets/images/Iconografia/Icono Profesionales.png" width="40"
              height="40"></i><br>Profesionales Titulados</button>
        <div class="notify"> <span class=""></span></div>
      </a>

      <a class="nav-link  " [routerLink]="['/inicio']" routerLinkActive="router-link-active">
        <button type="button" class="btn btn-rounded btn-outline-primary boton_personalizado"
          tooltip=" Este boton te permite llegar a la pantalla <b> Inicio </b> " theme="light" data-toggle="tooltip"><i
            [routerLink]="['/inicio']" routerLinkActive="router-link-active"></i><br>Regresar a inicio</button>
        <div class="notify"> <span class=""></span></div>
      </a>


    </div>






  </div>
  <br>
  <br>
  <br>


</section>


<section>

  <h3 align="center">¿Ya eres miembro? <a [routerLink]="['/login']" routerLinkActive="router-link-active">Inicia
      sesión</a>&nbsp;&nbsp;
    <button type="button" class="btn btn-success" (click)="home()">?<i class="bi bi-patch-question"></i></button>
  </h3>

</section>

<app-footer></app-footer>
