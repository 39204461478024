<!--==========================
    Footer
  ============================-->
  <footer id="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row  " id="pruebaDiv">


                <div class="col-lg-5 col-md-6 footer-links">
                    <h4>Menu:</h4>
                    <ul>
                        <li><a href="#/inicio" (click)="Urlinicio()">Inicio</a></li>
                        <li><a href="#/home" (click)="Urlhome()">Encontrar Empleo</a></li>
                        <li><a href="#/home2" (click)="Urlhome2()" >Publicar Empleo</a></li>
                        <li><a href="#/cursos" (click)="Urlcursos()">Capacítate</a></li>
                        <li><a href="#/contacto" (click)="UrlContacto()">Contactanos</a></li>
                    </ul>
                </div>

                <div class="col-lg-4 col-md-6 footer-contact">
                    <h4>Contacto</h4>
                    <p>
                      República del salvador y portugal<br>

                        <strong>Teléfono:</strong> 098-046-5341 / 098-350-2712<br>
                        <strong>Correo:</strong> info@jobandcare.com<br>
                    </p>

                </div>

                <div class="col-lg-2 col-md-6 footer-newsletter">
                    <h4>Redes Sociales</h4>
                    <div class="social-links">
                        <a href="https://www.facebook.com/Jobandcare-105854298507289" target="_blank"class="facebook" ><i class="fa fa-facebook"></i></a>
                        <a href="https://www.facebook.com/softlutionsEC" target="_blank"class="instagran" ><i class="fa fa-instagram"></i></a>
                        <a href="https://www.facebook.com/softlutionsEC" target="_blank"class="linkedin" ><i class="fa fa-linkedin"></i></a>
                        <a href="https://api.whatsapp.com/send?phone=593979228560&text=Hola JobandCare quiero mas información acerca de sus servicios, espero su respuesta por este medio, muchas gracias" target="_blank" class="whatsapp"><i class="fa fa-whatsapp"></i></a>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="container">
        <div class="copyright">
            &copy; Copyright  Job & Care brinda información y herramientas para ayudar a los empleados y empleadores a conectarse. Sin embargo, cada uno es el único responsable de seleccionar el perfil adecuado según sus requerimientos, así como de cumplir con todas las leyes aplicables con cualquier relación laboral que establezcan. La información que figura en los perfiles de los miembros, los puestos de trabajo y las solicitudes generadas cuentan con información específica que debe ser validada por las partes, y ninguna de las acciones generados por ellos es responsabilidad de JobandCare
        </div>
    </div>
</footer><!-- #footer -->
