
  <div class=" container p-3 " class="col-sm">
    <h3 align="center" id="color">PERFIL PUBLICADO</h3>
</div>


<div class="container">
    <div class="row">
        <div class="col-12">
            <table class="table table-striped table-responsive-md btn-table tabla"   id="tableColor" >
                <thead>
                    <tr>
                        <th scope="col" COLSPAN=1 style="background: azure">Nº</th>
                        <th scope="col" COLSPAN=1 style="background: azure">NOMBRE</th>
                        <th scope="col" COLSPAN=1 style="background: azure">CEDULA</th>
                        <th scope="col" COLSPAN=1 style="background: azure">CATEGORIA</th>
                        <th scope="col" COLSPAN=1 style="background: azure">FECHA CREACIÓN</th>
                   
                   
                 
                     
                   
                        <th scope="col" COLSPAN=1 style="background: azure">ESTADO</th>
                        <th scope="col" COLSPAN=1 style="background: azure">EDITAR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let form of  formularios| paginate: { itemsPerPage: 5, currentPage: totalRegistros }; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{form.nombre}}</td>
                        <td>{{form.cedula}}</td>
                        <td>{{form.categorias}}</td>
                        <td>{{form.fechaReporte |date:'dd-MM-yyyy'}}</td>
                     
                     
                      
                     
                     
                        <td class="text" [ngClass]="{'bg-primary text-white':form.estado=='PUBLICADO','bg-danger text-white':form.estado=='NO PUBLICADO'}">{{form.estado}}</td>
                        <td>
                            <button type="button" class="btn btn-outline-primary"
                                       style="width: 75px;height: 70;font-size: 50%;"
                            [routerLink]="['/dashboard/editarhojavidaprofesional/',form._id]"><b>EDITAR</b></button>
                        
                        </td>
                    </tr>                  
                </tbody>
            </table>
            <pagination-controls (pageChange)="totalRegistros = $event"></pagination-controls>
        </div>
    </div>
</div>
