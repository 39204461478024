
<div class=" container p-3 " class="col-sm">
    <h3 align="center" id="color">EMEPLEADORES PREMIUM</h3>
</div>


<div class="container ">
    <div class="row ">
        <div class="col-12">
            <table class="table table-hover table-responsive btn-table  " id="tableColor" >
                <thead>
                    <tr>
                        <th scope="col" COLSPAN=1 style="background: azure">Nº</th>
                        <th scope="col" COLSPAN=1 style="background: azure">TÍPO DE PLAN</th>
                        <th scope="col" COLSPAN=1 style="background: azure">FECHA SUCRIPCIÓN</th>
                        <th scope="col" COLSPAN=1 style="background: azure">FECHA VENCIMIENTO</th>
                        <th scope="col" COLSPAN=1 style="background: azure">VALOR</th>
                        <th scope="col" COLSPAN=1 style="background: azure">CORREO</th>
                        <th scope="col" COLSPAN=1 style="background: azure">TELÉFONO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let form of  formularios ; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{form.tipoPlan}}</td>
                        <td>{{form.fecha1 |date:'dd-MM-yyyy'}}</td>
                        <td>{{form.fecha2 |date:'dd-MM-yyyy'}}</td>
                        <td>{{form.valor}}</td>
                        <td>{{form.usuario.email}}</td>
                        <td>{{form.usuario.telefono}}</td>
                      
                    </tr>                  
                </tbody>
            </table>
         
        </div>
    </div>
</div>
